import { ComponentType, useCallback, useEffect, useRef, useState } from 'react'
import {
	selectAzureCommunicationToken,
	selectUsername,
} from '../../../features/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'
import {
	CallAgentProvider,
	CallClientProvider,
	CallProvider,
	createStatefulCallClient,
	DEFAULT_COMPONENT_ICONS,
	StatefulCallClient,
} from '@azure/communication-react'
import { Call, CallAgent } from '@azure/communication-calling'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { CallEndReasonCode } from './callEndReasonCodes'
import { logAzure } from '../actions'
import { selectExamId } from '../../../features/exam/selectors'
import { AzureLogger, setLogLevel } from '@azure/logger'
import { selectStoreId } from '../../../features/settings/selectors'
import notificationsActions from '../../../features/notifications/actions'
import { initializeIcons, registerIcons } from '@fluentui/react'

function withAzureClient<T>(WrappedComponent: ComponentType<T>) {
	return (props: JSX.IntrinsicAttributes & T) => {
		const dispatch = useDispatch()
		const displayName = useSelector(selectUsername)
		const { userId: roomUserId, token: roomToken } = useSelector(
			selectAzureCommunicationToken,
		)
		const store = useSelector(selectStoreId)
		const examId = useSelector(selectExamId)

		const [callClient, setCallClient] = useState<
			StatefulCallClient | undefined
		>(undefined)

		const [callAgent, _setCallAgent] = useState<CallAgent | undefined>(
			undefined,
		)
		const callAgentRef = useRef<CallAgent>()
		const [call, _setCall] = useState<Call | undefined>(undefined)
		const callRef = useRef<Call>()

		const setCallAgent = (callAgent: CallAgent | undefined) => {
			_setCallAgent(callAgent)
			callAgentRef.current = callAgent
		}

		const setCall = (call?: Call) => {
			_setCall(call)
			callRef.current = call
		}

		const [callKnownError, setCallKnownError] = useState<CallEndReasonCode>()
		const [callUnknownError, setCallUnknownError] = useState<string>()

		const disposeCallAgent = useCallback(async () => {
			try {
				if (callAgentRef && callAgentRef.current) {
					await callAgentRef.current.dispose()
				}
			} catch (error) {
				console.error(error)
			}
		}, [])

		// IF CALL IS SET, HANGUP, THEN DISPOSE CALLAGENT
		useEffect(() => {
			return () => {
				if (
					callRef &&
					callRef.current &&
					callRef.current.state === 'Connected'
				) {
					callRef.current.hangUp().then(() => disposeCallAgent())
				} else {
					disposeCallAgent().then()
				}
				window.acsLogBuffer = []
			}
		}, [disposeCallAgent])

		useEffect(() => {
			const initCallClientAndAgent = async () => {
				try {
					const statefulCallClient = createStatefulCallClient({
						userId: {
							communicationUserId: roomUserId,
						},
					})

					setLogLevel('info')
					window.acsLogBuffer = []
					AzureLogger.log = (...args) => {
						window.acsLogBuffer.push(...args)
						if (args[0].startsWith('azure:ACS:error')) {
							logAzure({
								action: 'AZURE_LOGGER_ERROR',
								store,
								callId: window.callId || '-',
								examId,
								displayName,
							})
						}
					}

					if (!callAgent && !callClient) {
						const callAgentResult = await statefulCallClient
							.createCallAgent(
								new AzureCommunicationTokenCredential(roomToken),
								{
									displayName: displayName,
								},
							)
							.then(callAgent => {
								return callAgent
							})

						callAgentResult.on('incomingCall', async e => {
							const incomingCall = e.incomingCall
							const callResult = await incomingCall.accept()
							setCall(callResult)
						})

						callAgentResult.on('callsUpdated', e => {
							e.removed.forEach(async call => {
								if (call && call.callEndReason) {
									if (call) {
										call.dispose()
										setCall(undefined)
									}
								}
							})
						})

						setCallAgent(callAgentResult)
					}
					setCallClient(statefulCallClient)
					initializeIcons()
					registerIcons({ icons: DEFAULT_COMPONENT_ICONS })
				} catch (error: any) {
					setCallUnknownError('Videocall, error during init: ' + error.message)
					logAzure({
						action: 'INIT_AZURE_CALL_SERVICE',
						store,
						callId: '',
						examId,
						displayName,
						error,
					})
				}
			}
			if (!callAgent && !callClient && roomToken) {
				initCallClientAndAgent()
			}
		}, [
			roomToken,
			roomUserId,
			displayName,
			store,
			examId,
			callAgent,
			callClient,
			call,
		])

		// SHOW ALERT WITH UNKNOWN ERROR, THEN CLEAN IT
		useEffect(() => {
			if (callUnknownError) {
				dispatch(
					notificationsActions.addNotification({
						type: 'error',
						message: callUnknownError,
						autoClose: true,
						closable: true,
					}),
				)
			}
			return () => {
				setCallUnknownError(undefined)
			}
		}, [dispatch, setCallUnknownError, callUnknownError])

		// SHOW ALERT WITH KNOWN ERROR, THEN CLEAN IT
		useEffect(() => {
			if (callKnownError) {
				dispatch(
					notificationsActions.addNotification({
						type: 'error',
						message: callKnownError.message,
						autoClose: true,
						closable: true,
					}),
				)
			}
			return () => {
				setCallKnownError(undefined)
			}
		}, [dispatch, setCallKnownError, callKnownError])

		return (
			<>
				{callClient && (
					<CallClientProvider callClient={callClient}>
						{callAgent && (
							<CallAgentProvider callAgent={callAgent}>
								<CallProvider call={call}>
									<>
										<WrappedComponent {...props} />
									</>
								</CallProvider>
							</CallAgentProvider>
						)}
					</CallClientProvider>
				)}
			</>
		)
	}
}

export default withAzureClient
