import { AppConfig } from './model/model'

const safeEnv = (key: string, defaultValue?: string): string => {
	const value: string = process.env[key] || ''
	const result: any = value || defaultValue

	// strict check because empty string must be evaluated as true
	if (result === undefined) {
		throw new Error(`Missing key in in .env file: ${key}`)
	}
	return result
}

const appConfig: AppConfig = {
	app: {
		name: safeEnv('REACT_APP_NAME', '$REACT_APP_NAME'),
		version: safeEnv('REACT_APP_VERSION', '$REACT_APP_VERSION'),
	},
	region: safeEnv('REACT_APP_REGION', '$REACT_APP_REGION'),
	apiUrl: safeEnv('REACT_APP_API_URL', '$REACT_APP_API_URL'),
	apiUrlV2: safeEnv('REACT_APP_API_URLV2', '$REACT_APP_API_URLV2'),
	apiUrlV3: safeEnv('REACT_APP_API_URLV3', '$REACT_APP_API_URLV3'),
	mediaUrl: safeEnv('REACT_APP_MEDIA_URL', '$REACT_APP_MEDIA_URL'),
	socketUrl: safeEnv('REACT_APP_SOCKET_URL', '$REACT_APP_SOCKET_URL'),
	environment: safeEnv('REACT_APP_ENVIRONMENT', '$REACT_APP_ENVIRONMENT'),
	logout: safeEnv('REACT_APP_LOGOUT_URL', '$REACT_APP_LOGOUT_URL'),
	login: {
		clientId: safeEnv(
			'REACT_APP_LOGIN_CLIENT_ID',
			'$REACT_APP_LOGIN_CLIENT_ID',
		),
		url: safeEnv('REACT_APP_LOGIN_URL', '$REACT_APP_LOGIN_URL'),
		redirectUri: safeEnv(
			'REACT_APP_LOGIN_REDIRECT_URI',
			'$REACT_APP_LOGIN_REDIRECT_URI',
		),
	},
	languages: ['en-US', 'sp-US'],
}

export default appConfig
