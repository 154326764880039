import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { hexToRgba, pxToRem } from '../../libs/style'
import theme from '../../styleguide/theme'

import ReplacementIcon from '../../styleguide/icons/ReplacementIcon'
import DropsIcon from '../../styleguide/icons/DropsIcon'

export const Wrapper = styled.div`
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding-bottom: ${pxToRem(theme.spacing(3))}rem;
	padding-right: ${pxToRem(theme.spacing(2))}rem;
`

export const TitleWithWidget = styled.div`
	display: flex;
	& > * {
		width: 50%;
	}
`

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export const BoxCLType = styled.div<{
	stretchHeight?: boolean
	columnMode?: boolean
}>`
	color: black;
	display: flex;
	flex-direction: ${props => (props.columnMode ? 'column' : 'row')};
	align-items: ${props => (props.stretchHeight ? 'stretch' : 'center')};
	column-gap: ${pxToRem(theme.spacing(2))}rem;
	row-gap: ${pxToRem(theme.spacing(1))}rem;
`

export const FirstLine = styled.div`
	color: ${theme.palette.background.secondary};
	font-size: 2rem;
	text-transform: capitalize;
	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		font-size: 1.8rem;
	}
`

export const SecondLine = styled.div`
	font-family: 'Ivar Display';
	font-style: italic;
	font-size: 3rem;
	text-transform: capitalize;
	color: ${theme.palette.background.secondary};
	padding-left: ${pxToRem(theme.spacing(1))}rem;
	margin-bottom: ${pxToRem(theme.spacing(3))}rem;
	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		margin-bottom: ${pxToRem(theme.spacing(2))}rem;
		padding-left: 0;
		font-size: 2.5rem;
	}
`

export const ThirdLine = styled.div`
	color: ${theme.palette.background.secondary};
	font-size: 1rem;
`

export const ImagesBox = styled.div`
	border: solid ${theme.palette.common.white}
		${pxToRem(theme.shape.borderThickness)}rem;
	background: linear-gradient(
		to bottom,
		${hexToRgba(theme.palette.grey[200], 0.6)},
		${hexToRgba(theme.palette.secondary.light, 0.6)}
	);
	padding: ${pxToRem(theme.spacing(3))}rem;
	border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	backdrop-filter: blur(${pxToRem(50)}rem);
	display: grid;
	gap: ${pxToRem(theme.spacing(1))}rem;
	overflow: hidden;

	&.horizontal {
		grid-auto-flow: column;
	}

	&.vertical {
		grid-auto-flow: row;
	}
`
export const EyeTableBox = styled.div<{
	withGap?: boolean
	isLensType?: boolean
	stretchWidth?: boolean
}>`
	border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	backdrop-filter: blur(${props => (props.isLensType ? '6px' : '20px')});
	padding: 0 ${pxToRem(theme.spacing(2))}rem ${pxToRem(theme.spacing(2))}rem;
	padding-top: ${props =>
		props.stretchWidth
			? pxToRem(theme.spacing(2)) + 'rem'
			: pxToRem(theme.spacing(3)) + 'rem'};
	background-image: radial-gradient(
		circle at 49% 8%,
		${props => (props.isLensType ? 'transparent' : '#223550')},
		${props => (props.isLensType ? 'transparent' : '#20233d 70%')}
	);
	display: flex;
	flex-direction: column;
	flex: ${props => (props.isLensType || props.stretchWidth ? '1' : 'none')};
	border: ${props =>
		props.isLensType ? '1px solid rgba(255,255,255,0.4)' : 'none'};
	row-gap: ${props => (props.withGap ? `${pxToRem(theme.spacing(2))}rem` : 0)};
	overflow-wrap: break-word;
	margin-bottom: ${props =>
		props.isLensType ? `${pxToRem(theme.spacing(4))}rem` : 'none'};
	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		padding: ${pxToRem(theme.spacing(2))}rem;
	}
`

export const TitleWithIcon = styled.span<{
	CLTitle?: boolean
	isSubtitle?: boolean
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${pxToRem(theme.spacing(1))}rem;
	text-transform: uppercase;
	padding-bottom: ${pxToRem(theme.spacing(2))}rem;
	color: ${props =>
		props.CLTitle
			? props.isSubtitle
				? theme.palette.secondary.light
				: theme.palette.common.black
			: theme.palette.common.white};
`

export const TableTitle = styled.span<{
	CLTitle?: boolean
	isSubtitle?: boolean
	isSame?: boolean
}>`
	text-transform: uppercase;
	color: ${props =>
		props.CLTitle
			? props.isSubtitle
				? theme.palette.secondary.light
				: theme.palette.common.black
			: theme.palette.common.white};
	max-width: ${props => (props.isSame ? '65%' : 'none')};
`

const LensDataWrapper = styled.div<{ isTonometry: boolean }>`
	margin: ${pxToRem(theme.spacing(2))}rem 0 ${pxToRem(theme.spacing(0.5))}rem;
	padding: ${pxToRem(theme.spacing(2))}rem;
	border-radius: ${pxToRem(theme.spacing(2))}rem;
	border: solid 1px rgba(97, 164, 255, 0.4);
	display: flex;
	flex-direction: row;

	${props =>
		props.isTonometry &&
		`
		${EyeBadge} {
			width: ${pxToRem(theme.spacing(7))}rem;
			height: ${pxToRem(theme.spacing(7))}rem;

			& .badge-description {
				display: none;
			}

			& .badge-title {
				font-size: ${theme.typography.fontSizes.XL}
			}
		}
	`}
`
export const LensCell = styled.div<{
	last?: boolean
	cells: number
	border?: boolean
}>`
	flex: ${props => 1 / props.cells};
	color: ${theme.palette.common.white};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	${props =>
		props.last || props.border === false
			? ''
			: `
    	&::after {
		content: ' ';
		display: block;
		position: absolute;
		height: 100%;
		background: linear-gradient(to bottom, rgba(97, 164, 255, 0.1), rgba(97, 164, 255, 0.4) 51%, rgba(97, 164, 255, 0.1));;
		width: 1px;
		left: 100%;
		top: 0;
        bottom: 0;
	}
    `}
`

const EyeBadge = styled.div`
	color: ${theme.palette.common.black};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.badge-title {
		font-size: ${theme.typography.fontSizes.L};
	}
	.badge-description {
		font-size: ${theme.typography.fontSizes.S};
	}
	width: ${pxToRem(theme.spacing(10))}rem;
	height: ${pxToRem(theme.spacing(10))}rem;
	padding: ${pxToRem(theme.spacing(2))}rem ${pxToRem(theme.spacing(1))}rem
		${pxToRem(theme.spacing(2))}rem;
	border-radius: ${pxToRem(theme.spacing(9))}rem;
	backdrop-filter: ${pxToRem(theme.spacing(9))}rem;
	background: rgb(238, 232, 227);
	background: linear-gradient(
		210deg,
		rgba(238, 232, 227, 1) 0%,
		rgba(238, 232, 227, 1) 8%,
		rgba(97, 164, 255, 1) 102%
	);
`

const LensValue = styled.span`
	font-size: ${theme.typography.fontSizes.XXL};
	color: ${theme.palette.common.white};
`

const LensLabel = styled.span`
	color: ${theme.palette.secondary.light};
	font-size: ${theme.typography.fontSizes.M};
	text-align: center;
`

const StyledUl = styled.ul`
	list-style: none;
	color: white;
	padding: 0 ${pxToRem(theme.spacing(1.5))}rem;
	line-height: ${pxToRem(theme.spacing(2.5))}rem;
`

const StyledLi = styled.li`
	&:before {
		content: '• ';
		color: ${theme.palette.secondary.light};
		font-weight: bold;
		display: inline-block;
		width: 1em;
	}
`

const StyledEyeTableBox = styled(EyeTableBox)`
	max-height: ${pxToRem(theme.spacing(14.5))}rem;
	overflow-y: auto;
	& {
		scrollbar-width: thin; /* Firefox */
	}
	&::-webkit-scrollbar {
		width: ${pxToRem(4)}rem; /* width of the entire scrollbar */
		height: ${pxToRem(4)}rem;
	}

	&::-webkit-scrollbar-track {
		background: ${theme.palette.secondary
			.main}; /* color of the tracking area */
		border-radius: ${pxToRem(20)}rem;
		margin: ${pxToRem(theme.spacing(2))}rem 0px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${theme.palette.secondary
			.light}; /* color of the scroll thumb */
		border-radius: ${pxToRem(20)}rem; /* roundness of the scroll thumb */
		border-color: transparent; /* creates padding around scroll thumb */
	}
`

export const LensDataTypeWrapper = styled.div<{ isHorizontal?: boolean }>`
	display: ${props => (props.isHorizontal ? 'flex' : 'block')};
	flex-direction: ${props => (props.isHorizontal ? 'row' : 'column')};
	column-gap: ${pxToRem(theme.spacing(2))}rem;
	${props => props.isHorizontal && 'align-items: start;'}
`

export const EyeDataRow: React.FC<{
	data: { value: number | string; label: string }[]
	eye: 'OD' | 'OS'
	className?: string
	tonometry?: boolean
}> = ({ data, eye, className, tonometry = false }) => {
	const { t } = useTranslation()

	return (
		<LensDataWrapper isTonometry={tonometry} className={className}>
			<LensCell border={false} cells={data.length + 1} className="lens-cell">
				<EyeBadge>
					<span className="badge-title">{eye}</span>
					<span className="badge-description">{t(`app.${eye}Eye`)}</span>
				</EyeBadge>
			</LensCell>
			{data.map(({ value, label }, index) => (
				<LensCell
					cells={data.length + 1}
					className="lens-cell"
					key={index}
					last={index === data.length - 1}
				>
					<LensValue>{value}</LensValue>
					<LensLabel>{t(label)}</LensLabel>
				</LensCell>
			))}
		</LensDataWrapper>
	)
}

export const LensDataType: React.FC<{
	data: any
	isSameProduct?: boolean
	eye: 'OD' | 'OS'
	className?: string
}> = ({ data, eye, isSameProduct }) => {
	const { t } = useTranslation()

	return (
		<EyeTableBox withGap={true} isLensType={true}>
			<BoxCLType>
				{(isSameProduct || eye === 'OD') && (
					<EyeBadge>
						<span className="badge-title">{'OD'}</span>
						<span className="badge-description">{t('app.ODEye')}</span>
					</EyeBadge>
				)}
				{(isSameProduct || eye === 'OS') && (
					<EyeBadge>
						<span className="badge-title">{'OS'}</span>
						<span className="badge-description">{t('app.OSEye')}</span>
					</EyeBadge>
				)}
				<TableTitle isSame={!isSameProduct} CLTitle={true}>
					{data.LensType.value}
				</TableTitle>
			</BoxCLType>
			<BoxCLType stretchHeight={true} columnMode={!isSameProduct}>
				<StyledEyeTableBox stretchWidth={true}>
					<TitleWithIcon CLTitle={true} isSubtitle={true}>
						<ReplacementIcon />
						{t(data.ReplacementFrequency.label)}
					</TitleWithIcon>
					<StyledUl>
						{data.Replenishment ? (
							<StyledLi>{data.Replenishment}</StyledLi>
						) : (
							<StyledLi>
								{data.ReplacementFrequency.value !== undefined
									? data.ReplacementFrequency?.value.join(', ')
									: '-'}
							</StyledLi>
						)}
					</StyledUl>
				</StyledEyeTableBox>
				<StyledEyeTableBox stretchWidth={true}>
					<TitleWithIcon CLTitle={true} isSubtitle={true}>
						<DropsIcon />
						{t(data.Cleaning.label)}
					</TitleWithIcon>
					<StyledUl>
						{data.DisinfectingSolution ? (
							<StyledLi>{data.DisinfectingSolution}</StyledLi>
						) : (
							<StyledLi>
								{data.Cleaning.value !== undefined
									? data.Cleaning.value.join(', ')
									: '-'}
							</StyledLi>
						)}
					</StyledUl>
				</StyledEyeTableBox>
			</BoxCLType>
		</EyeTableBox>
	)
}
